* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
    sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#root {
  overflow: hidden;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  text-align: justify;
  background: #f0f0f0;
  color: lightpink;
  font-size: 2em;
  line-height: 1.2;
  font-weight: 600;
}

.poems {
  padding-bottom: 36px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 36px);
}

@media screen and (min-width: 480px) {
  .container {
    font-size: 3em;
  }
}

@media screen and (min-width: 640px) {
  .container {
    font-size: 4em;
  }
}

@media screen and (min-width: 960px) {
  .container {
    font-size: 6em;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    font-size: 8em;
  }
}

svg {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70vw;
  max-width: 600px;
  transform-origin: 0% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 10em;
}

#bg {
  fill: #2da1dc;
}
#sweater > path:nth-child(1) {
  fill: #60d882;
}
#sweater > path:nth-child(2) {
  fill: #37c47a;
}
#sweater > path:nth-child(3) {
  fill: #37c47a;
}

#face > path:nth-child(1) {
  fill: #ffe2b8;
}
#face > path:nth-child(2) {
  fill: #ffc987;
}
#eye > path:nth-child(2) {
  fill: #f0f0f0;
}
#eye > g:first-of-type > path:nth-child(1) {
  fill: #159e97;
}
#eye > g:first-of-type > path:nth-child(2) {
  fill: #0f9390;
}
#eye > g:last-of-type > path:nth-child(2) {
  fill: #231f20;
}
#mouth > path:nth-child(1) {
  fill: #f24976;
}
#mouth > path:nth-child(2) {
  fill: #b7092b;
}
#mouth > path:nth-child(3) {
  fill: #a00026;
}
#mouth > path:nth-child(4) {
  fill: #ffffff;
}
#hair > path:nth-of-type(1) {
  fill: #62317c;
}
#hair > path:nth-of-type(2) {
  fill: #62317c;
}
#hair > path:nth-of-type(3) {
  fill: #62317c;
}
